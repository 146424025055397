<template>
  <div class="mainContent">
    <img src="/img/cert_top.png" class="top_img" />
    <div class="mainContentBox">
      <table>
        <tbody>
          <tr>
            <td>学员姓名:{{user.name}}</td>
          </tr>
          <tr>
            <td>身份证号:{{user.citizenId}}</td>
          </tr>
          <tr>
            <td>
              学习年度:{{user.year}}年 <span style="float: right">成绩:{{user.credits}}分</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <td>继续教育内容</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item,index in courseList" :key="item.id">
            <td>{{index+1}}.{{item.name}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import store from "@/store/simpstore";
export default {
  data() {
    return {
      user: {},
      courseList: []
    };
  },
  mounted: function () {
    const id = this.$route.params.id;
    store.hideMenu();
    this.$net.userCreditsInfo(id).then(({ data }) => {
      console.log(`打印user:${data.user}`)
      if(data.user==undefined){
        //本地测试
        // window.location.href = 'http://192.168.31.113:8088/'
        //生产环境
        window.location.href = 'http://36.134.67.33:9085/'
        return
      }if(data.user.credits==0){
        //本地测试
        // window.location.href = `http://192.168.31.113:8088/cert/getfindcert?name=${data.user.name}&citizenId=${data.user.citizenId}`;
        //生产环境
        window.location.href = `http://36.134.67.33:9085/cert/getfindcert?name=${data.user.name}&citizenId=${data.user.citizenId}`;
        return
      }
      this.user = data.user;
      this.courseList = JSON.parse(data.user.courseJson)
    });
  },
};
</script>
<style scoped>
.top_img {
  position: absolute;
  width: 163px;
  height: 46px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.mainContent {
  position: relative;
  width: calc(100% - 40px);
  border: 1px solid #ffa1a1;
  padding: 12px;
  margin: 13px auto;
}
.mainContentBox {
  width: 100%;
  border: 1px solid #ffa1a1;
  margin: 0 auto;
}
table:first-child {
  margin: 40px auto 15px auto;
}
table {
  display: block;
  width: calc(100% - 32px);
  margin: 0 auto;
  border: 1px solid #999;
  margin-bottom: 15px;
  font-size: 13px;
  color: rgb(63, 62, 62);
  font-weight: bold;
  letter-spacing: 1px;
}
thead tr {
    display: block;
    width: 100%;
    background-color: #ffd5d5;
    height: 36px;
    line-height: 36px;
}
tbody, thead {
    width: 100%;
    display: block;
}
tbody tr {
  display: block;
  width: 95%;
  margin: 0 auto;
  height: 36px;
  line-height: 36px;
  padding: 0 6px;
  border-bottom: 1px dashed #eee;
}
thead td {
    width: 100%;
    display: block;
    text-align: center;
}
td {
  width: 100%;
  display: block;
}
</style>
